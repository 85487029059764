import React, {
  useReducer,
  useEffect,
  useLayoutEffect,
} from "react"
import CartCtx from "./ctx"
import initialState from "./state"
import actions from "./actions"

const CartProvider = ({
  children,
  onLoad = () => false,
  onStateChange = () => false,
}) => {
  const [state, dispatch] = useReducer(actions, initialState)

  useLayoutEffect(() => {
    onLoad(dispatch)
    const cart = localStorage.getItem("CartCtx")
    if (cart) {
      dispatch({
        type: "loadCart",
        payload: {
          cart: JSON.parse(cart),
        },
      })
    }
  }, [])

  useEffect(() => {
    console.log("CartCtx state ", state)
    onStateChange(state)
    localStorage.setItem("CartCtx", JSON.stringify(state))
  }, [state])

  return (
    <CartCtx.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CartCtx.Provider>
  )
}

export { CartProvider, CartCtx }
