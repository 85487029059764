import React, {
  useReducer,
  useCallback,
  useLayoutEffect,
} from "react"
import { throttle } from "lodash"
import WindowCtx from "./ctx"
import initialState from "./state"
import actions from "./actions"

const WindowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(actions, initialState)

  const onScroll = useCallback(
    throttle(() => {
      dispatch({
        type: "changeWindow",
        payload: {
          y: window.scrollY,
          x: window.scrollX,
          scrolling: true,
        },
      })
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (state === true) {
          dispatch({
            type: "changeWindow",
            payload: {
              scrolling: false,
            },
          })
        }
      }, 1000)
    }, 166),
    []
  )

  const onResize = useCallback(
    throttle(() => {
      dispatch({
        type: "changeWindow",
        payload: {
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth,
          browserHeight: window.outerHeight,
          browserWidth: window.outerWidth,
        },
      })
    }, 166),
    [
      // window.innerHeight,
      // window.innerWidth,
      // window.outerHeight,
      // window.outerWidth,
    ]
  )

  useLayoutEffect(() => {
    window.onscroll = onScroll
    window.onresize = onResize
  }, [])

  // useEffect(() => {
  //   console.log("WindowCtx state ", state)
  //   onStateChange(state)
  // }, [state])

  return (
    <WindowCtx.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </WindowCtx.Provider>
  )
}

export { WindowProvider, WindowCtx }
