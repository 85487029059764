import React, { useState, useEffect } from "react"
import { AppCtx, useContextState } from "@components/contexted"

import "./loaderInit.modal.scss"

const MainAppLoader = () => {
  const [loaded, setter] = useState(false)
  const { imBusy } = useContextState(AppCtx, "imBusy")

  useEffect(() => {
    if (imBusy === false) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        setter(true)
      }, 333)
    }
  }, [imBusy])

  return (
    <React.Fragment>
      {!loaded && (
        <div className={"loader"}>
          {" "}
          <div className={"loaderInit"}></div>
        </div>
      )}
    </React.Fragment>
  )
}

export default MainAppLoader
