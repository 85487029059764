import { request } from "./request"
import WC_GLOBALS from "../env/WOOCOMMERCE"
const { KEY, SECRET } = WC_GLOBALS.APIAUTH

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
  }

  getAllProducts() {
    const url = this.route + `/products`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  getAllCoupons() {
    const url = this.route + `/coupons?per_page=100`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }
  postCreateOrder(customer, cart, shipping, coupon = {}) {
    const url = this.route + `/orders`
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [shipping],
        ...coupon,
      },
    }

    return request(url, config)
  }

  changeOrder(id, data = { status: "completed" }) {
    const url = this.route + `/orders/${id}`
    const config = {
      method: "PUT",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data,
    }

    return request(url, config)
  }

  cancelOrder(id, data = { status: "cancelled" }) {
    const url = this.route + `/orders/${id}`
    const config = {
      method: "PUT",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data,
    }

    return request(url, config)
  }

  searchByPaymentId(paymentId) {
    const url = this.route + `/orders?search=${paymentId}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }

    return request(url, config)
  }

  search(phrase = "") {
    const urlProducts = this.route + `/products?search=${phrase}&per_page=100`
    const configProducts = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    const orders = () => request(urlProducts, configProducts)

    const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}&per_page=100`
    const configPosts = {
      method: "GET",
    }
    const posts = () => request(urlPosts, configPosts)

    const urlPages = `/wp-json/wp/v2/pages?search=${phrase}&per_page=100`
    const configPages = {
      method: "GET",
    }
    const pages = () => request(urlPages, configPages)

    return Promise.all([orders(), posts(), pages()])
  }

  sendMail(email, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/106/feedback"
    const data = new FormData()
    data.append("email", email)
    data.append("from", "gladiators")

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  sendResult(email, stack, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/372/feedback"
    const data = new FormData()
    data.append("email", email)
    data.append("stack", stack)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  sendContactForm(email, subject, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/107/feedback"
    const data = new FormData()
    data.append("email", email)
    data.append("subject", subject)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  sendFeedback(firstQuestion, firstAnswer, secondQuestion, secondAnswer) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/309/feedback"
    const data = new FormData()

    data.append("first-question", firstQuestion)
    data.append("first-question-answer", firstAnswer)
    data.append("second-question", secondQuestion)
    data.append("second-question-answer", secondAnswer)

    const config = {
      method: "POST",
      multipart: true,
      data,
    }
    return request(url, config)
  }
  getDeliveryOptions() {
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods
    const url = "/wp-json/wc/v3/shipping/zones"
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config).then((data) => {
      const [selected] = data.filter((el) => el.name === "Remote")
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`
      const config = {
        method: "GET",
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        },
      }
      return request(url, config)
    })
  }
  loginCustomer(email, password) {
    const url = `/wp-json/wp/v2/users/login`
    const config = {
      method: "POST",
      data: {
        email,
        password,
      },
    }
    return request(url, config).then(({ id }) => {
      const url = `/wp-json/wc/v3/customers/${id}`
      const config = {
        method: "GET",
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        },
      }
      return request(url, config)
    })
  }
  createCustomer(customer) {
    const url = "/wp-json/wc/v3/customers"
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        email: customer.email,
        first_name: customer.first_name,
        last_name: customer.last_name,
        password: customer.password,
        username: customer.email,
        billing: customer,
        shipping: customer,
      },
    }

    return request(url, config)
  }

  // testowe id 9 dla test2/test2
  updateCustomer(customer) {
    const url = "/wp-json/wc/v3/customers/9"
    const config = {
      method: "PUT",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        email: customer.email,
        first_name: customer.first_name,
        last_name: customer.last_name,
        password: customer.password,
        username: customer.username,
        billing: customer,
        shipping: customer,
      },
    }
    return request(url, config)
  }

  createProductReview(id, form) {
    const url = "/wp-json/wc/v3/products/reviews"
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        product_id: Number(id),
        ...form,
      },
    }
    return request(url, config)
  }
  retrieveAllReviews() {
    const url = `/wp-json/wc/v3/products/reviews`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  retrieveProductReview(id) {
    const url = `/wp-json/wc/v3/products/reviews?product=${id}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  commentPost(postId, author, email, comment) {
    const url = "/wp-json/wp/v2/comments"
    const config = {
      method: "POST",
      data: {
        post: postId,
        author_name: author,
        author_email: email,
        content: comment,
      },
    }
    return request(url, config)
  }

  retrievePostComments(postId) {
    const url = `/wp-json/wp/v2/comments/?post=${postId}`
    const config = {
      method: "GET",
    }
    return request(url, config)
  }
}

const WPApi = new WPAPI()

export default WPApi
