import React, { useCallback } from "react"
import { Provider } from "react-redux"
import {
  CartProvider,
  CustomerProvider,
  SearchProvider,
  AppProvider,
  WindowProvider,
  WindowCtx,
  useActions,
  AppCtx,
} from "@components/contexted"

import store from "@stores"
import { getSearchData, getShippingMethods } from "./app.logic"

import Loader from "./InitLoading"

const onAppInit = (dispatch) => {}

const onInitSearchData = (appLoaded) => (dispatch) => {
  appLoaded()
  // load search data from storage
  let searchData = JSON.parse(window.localStorage.getItem("searchData"))
  if (searchData) {
    dispatch({
      type: "loadDataFromApi",
      payload: searchData,
    })
  } else {
    getSearchData()
      .then((data) => {
        dispatch({
          type: "loadDataFromApi",
          payload: data,
        })
      })
      .catch((err) => {
        dispatch({
          type: "onRequestError",
          payload: err,
        })
      })
  }
}

const onInitCartData = (dispatch) => {
  getShippingMethods()
    .then((data) => {
      dispatch({
        type: "setShippings",
        payload: data,
      })
    })
    .catch((err) => {
      dispatch({
        type: "onRequestError",
        payload: err,
      })
    })
}

const OuterProviders = ({ children }) => {
  return (
    <WindowProvider>
      <AppProvider onLoad={onAppInit}>{children}</AppProvider>
    </WindowProvider>
  )
}

const InnerProviders = ({ children }) => {
  const { loaded } = useActions(AppCtx, "loaded")
  const onLoadSearchData = useCallback(onInitSearchData(loaded))

  return (
    <Provider store={store}>
      <SearchProvider onLoad={onLoadSearchData}>
        <CustomerProvider>
          <CartProvider onLoad={onInitCartData}>
            <Loader />
            {children}
          </CartProvider>
        </CustomerProvider>
      </SearchProvider>
    </Provider>
  )
}

export default ({ element }) => {
  return (
    <OuterProviders>
      <InnerProviders>{element}</InnerProviders>
    </OuterProviders>
  )
}

export { WindowCtx }
